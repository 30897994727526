import store from "@/state/store";

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login.vue"),
    meta: {
      title: "Login",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/account/register.vue"),
    meta: {
      title: "Register",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("../views/account/forgot-password.vue"),
    meta: {
      title: "Forgot Password",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/",
    name: "default",
    meta: {
      title: "Dashboard",
      authRequired: true,
    },
    component: () => import("../views/dashboard/ecommerce/index.vue"),
  },
  {
    path: "/dashboard/crypto",
    name: "dashboard-crypto",
    meta: {
      title: "Crypto",
      authRequired: true,
    },
    component: () => import("../views/dashboard/crypto/index"),
  },
  {
    path: "/dashboard/projects",
    name: "dashboard-projects",
    meta: {
      title: "Projects", authRequired: true,
    },
    component: () => import("../views/dashboard/projects/index"),
  },
  {
    path: "/dashboard/crm",
    name: "dashboard-crm",
    meta: {
      title: "CRM", authRequired: true,
    },
    component: () => import("../views/dashboard/crm/index"),
  },
  {
    path: "/dashboard/analytics",
    name: "dashboard-analytics",
    meta: {
      title: "Analytics", authRequired: true,
    },
    component: () => import("../views/dashboard/analytics/index"),
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      title: "Logout", authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
          store.dispatch("auth/logOut");
        } else {
          store.dispatch("authfack/logout");
        }
        const authRequiredOnPreviousRoute = routeFrom.matched.some((route) =>
          route.push("/login")
        );
        // Navigate back to previous page, or home as a fallback
        next(
          authRequiredOnPreviousRoute ? { name: "default" } : { ...routeFrom }
        );
      },
    },
    component: () => import("../views/auth/logout/basic")
  },
  {
    path: '/auth/404',
    name: '404',
    meta: {
      title: "Error 404", authRequired: true,
    },
    component: () => import('../views/auth/errors/404')
  },
  {
    path: '/auth/500',
    name: '500',
    meta: {
      title: "Error 500", authRequired: true,
    },
    component: () => import('../views/auth/errors/500')
  },
  {
    path: '/auth/404-basic',
    name: '404-basic',
    meta: {
      title: "Error 404", authRequired: true,
    },
    component: () => import('../views/auth/errors/404-basic')
  },
  {
    path: '/auth/404-cover',
    name: '404-cover',
    meta: {
      title: "Error 404", authRequired: true,
    },
    component: () => import('../views/auth/errors/404-cover')
  },
  {
    path: '/auth/ofline',
    name: 'ofline',
    meta: {
      title: "Oflone", authRequired: true,
    },
    component: () => import('../views/auth/errors/ofline')
  },
  {
    path: '/auth/logout-basic',
    name: 'logout-basic',
    meta: {
      title: "Logout", authRequired: true,
    },
    component: () => import('../views/auth/logout/basic')
  },
  {
    path: '/auth/logout-cover',
    name: 'logout-cover',
    meta: {
      title: "Logout", authRequired: true,
    },
    component: () => import('../views/auth/logout/cover')
  },
  {
    path: '/auth/success-msg-basic',
    name: 'success-msg-basic',
    meta: {
      title: "Success Message", authRequired: true,
    },
    component: () => import('../views/auth/success-msg/basic')
  },
  {
    path: '/auth/success-msg-cover',
    name: 'success-msg-cover',
    meta: {
      title: "Success Message", authRequired: true,
    },
    component: () => import('../views/auth/success-msg/cover')
  },
  {
    path: "/apps-file-manager",
    name: "file manager",
    meta: { title: "File Manager", authRequired: true },
    component: () => import("../views/apps/filemanager"),
  },
  {
    path: '/tool/category',
    name: 'toolCategory',
    meta: {
      title: "Tool category", authRequired: true,
    },
    component: () => import('../views/pages/tool/tool-category.vue')
  },
  {
    path: "/tool/category/add",
    name: "toolCategoryAdd",
    meta: { title: "Tool category", authRequired: true },
    component: () => import("../views/pages/tool/tool-category-change.vue"),
  },
  {
    path: "/tool/category/change/:id",
    name: "toolCategoryChange",
    meta: { title: "Meme category", authRequired: true },
    component: () => import("../views/pages/tool/tool-category-change.vue"),
  },
  {
    path: '/tool',
    name: 'tool',
    meta: {
      title: "Tool", authRequired: true,
    },
    component: () => import('../views/pages/tool/tool.vue')
  },
  {
    path: "/tool/add",
    name: "toolAdd",
    meta: { title: "Tool", authRequired: true },
    component: () => import("../views/pages/tool/tool-change.vue"),
  },
  {
    path: "/tool/change/:id",
    name: "toolChange",
    meta: { title: "Tool", authRequired: true },
    component: () => import("../views/pages/tool/tool-change.vue"),
  },
  {
    path: '/emoji',
    name: 'emoji',
    meta: {
      title: "Emoji", authRequired: true,
    },
    component: () => import('../views/pages/emoji/emoji.vue')
  },
  {
    path: "/emoji/add",
    name: "emojiAdd",
    meta: { title: "Emoji", authRequired: true },
    component: () => import("../views/pages/emoji/emoji-change.vue"),
  },
  {
    path: "/emoji/change/:id",
    name: "emojiChange",
    meta: { title: "Emoji", authRequired: true },
    component: () => import("../views/pages/emoji/emoji-change.vue"),
  },
  {
    path: '/emoji/category',
    name: 'emojiCategory',
    meta: {
      title: "Emoji category", authRequired: true,
    },
    component: () => import('../views/pages/emoji/emoji-category.vue')
  },
  {
    path: "/emoji/category/add",
    name: "emojiCategoryAdd",
    meta: { title: "Emoji category", authRequired: true },
    component: () => import("../views/pages/emoji/emoji-category-change.vue"),
  },
  {
    path: "/emoji/category/change/:id",
    name: "emojiCategoryChange",
    meta: { title: "Emoji category", authRequired: true },
    component: () => import("../views/pages/emoji/emoji-category-change.vue"),
  },
  {
    path: "/setting/menu",
    name: "menuManager",
    meta: { title: "Menu manager", authRequired: true },
    component: () => import("../views/pages/menu/menu.vue"),
  },
  {
    path: "/setting/menu/add",
    name: "menuManagerAdd",
    meta: { title: "Menu manager", authRequired: true },
    component: () => import("../views/pages/menu/menu-change.vue"),
  },
  {
    path: "/setting/menu/change/:id",
    name: "menuManagerChange",
    meta: { title: "Menu", authRequired: true },
    component: () => import("../views/pages/menu/menu-change.vue"),
  },
  {
    path: "/setting/cache",
    name: "settingCache",
    meta: { title: "Cache", authRequired: true },
    component: () => import("../views/pages/setting/cache.vue"),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notfound404',
    component: import('../views/auth/errors/404')
  },
];
