export const state = {
    isLoading: false
}

export const mutations = {
    SET_LOADING(state, newValue) {
        state.isLoading = newValue
    }
}

export const getters = {
    // Whether the user is currently logged in.
    isLoading(state) {
        return !!state.isLoading
    }
}

export const actions = {
    setLoading({ commit }, result = {}) {
        commit('SET_LOADING', result ?? false)
    },

}
