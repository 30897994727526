const authUser = sessionStorage.getItem('authUser');
export const state = {
    currentUser: authUser ? JSON.parse(authUser) : authUser,
}

export const mutations = {
    SET_CURRENT_USER(state, newValue) {
        state.currentUser = newValue
        saveState('auth.currentUser', newValue)
    }
}

export const getters = {
    // Whether the user is currently logged in.
    loggedIn(state) {
        return !!state.currentUser
    }
}

export const actions = {
    // This is automatically run in `src/state/store.js` when the app
    // starts, along with any other actions named `init` in other modules.
    // eslint-disable-next-line no-unused-vars
    init({ state, dispatch }) {
        dispatch('validate')
    },

    // Logs in the current user.
    logIn({ commit, dispatch, getters }, { user } = {}) {
        if (getters.loggedIn) return dispatch('validate')
        saveState('authUser', user);
        commit('SET_CURRENT_USER', user)
    },

    // Logs out the current user.
    logOut() {
        localStorage.removeItem("userid");
        localStorage.removeItem("jwt");
        deleteState('authUser')
        deleteState('auth.currentUser')
        // commit('SET_CURRENT_USER', null)
    },

    // register the user
    register({ commit, dispatch, getters }, { user } = {}) {
        if (getters.loggedIn) return dispatch('validate')
        commit('SET_CURRENT_USER', user)
    },

}

// ===
// Private helpers
// ===

function saveState(key, state) {
    window.sessionStorage.setItem(key, JSON.stringify(state))
}

function deleteState(key) {
    window.sessionStorage.removeItem(key)
}